import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import { BooksProps } from 'models/BooksProps';
import React from 'react';
import * as Styled from './CoverImageStyles';

const CoverImage: React.FC<BooksProps> = ({ books, selectedIndex }) => {
  const { locale } = useIntl();

  return (
    <Styled.CoverImageInner>
      {books.map((book, index) => {
        let isSelected = index === selectedIndex;
        return (
          <Styled.CoverImageWrapper key={index} animate={isSelected ? 'visible' : 'hidden'}>
            <Img fluid={book.cover} alt={book[locale].title} />
          </Styled.CoverImageWrapper>
        );
      })}
    </Styled.CoverImageInner>
  );
};

export default CoverImage;
