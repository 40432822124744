import Img from 'gatsby-image';
import { BooksProps } from 'models/BooksProps';
import React from 'react';
import * as Styled from './BacgroundImageStyles';

const BackgroundImage: React.FC<BooksProps> = ({ books, selectedIndex }) => {
  return (
    <>
      {books.map((book, index: number) => {
        let isSelected = index === selectedIndex;
        return (
          <Styled.BgImage key={index} animate={isSelected ? 'visible' : 'hidden'}>
            <Img fluid={book.background_image} />
          </Styled.BgImage>
        );
      })}
    </>
  );
};

export default BackgroundImage;
