import { graphql, useStaticQuery } from 'gatsby';

export const getDefaultIndex = (): number => {
  const isBrowser = typeof window !== `undefined`;

  if (isBrowser) {
    const hashIndex = window.location.hash.slice(1);
    const hashIndexNumber = parseInt(hashIndex, 10);

    return !isNaN(hashIndexNumber) ? hashIndexNumber : 0;
  }

  return 0;
};

const data = [
  {
    trailer: 'sxOZHJI2KTo',
    review: 'https://lubimyczytac.pl/ksiazka/4899209/topielica-ze-switezi#opinie',
    bookstore: 'https://lubimyczytac.pl/ksiazka/4899209/topielica-ze-switezi#kup',
    background_image: '',
    cover: '',
    pl: {
      quotation: 'Jeśli Ty nie wierzysz w siebie, nikt inny także w Ciebie nie uwierzy',
      description:
        'Przeprowadzka na Białoruś nie jest szczytem marzeń nastolatka. Szesnastoletni Wojtek nie ma jednak wyboru, gdyż jego ojciec właśnie tam otrzymał intratną propozycję pracy. Chłopak wie, że ten krok jest ojcu potrzebny, być może to właśnie on pomoże mu wyrwać się z oków wspomnień o śmierci matki i zacząć żyć teraźniejszością. Wojtek nie ma pojęcia, że to, co spotka nad osławionym przez Mickiewicza jeziorem Świteź, na zawsze odmieni jego życie.\r\nSvetlana marzy o tym, by stać się zwyczajną dziewczyną - chodzić do szkoły, mieć przyjaciół, być może zakochać się. Siedemnastolatce w tych planach przeszkadza jeden fakt - jest wodją, topielicą, demonem, który zgodnie ze swoją naturą powinien wabić i zabijać ludzi, gdy tylko wkroczą na jej terytorium.\r\nGdy ścieżki tych dwojga się przetną, zmieni się życie każdego z nich. Nie sprawi tego nagły wybuch uczucia, ale wspólna walka o to, co większości jest dane już przy urodzeniu - o normalność i... śmiertelność. Ta miłość rodziła się powoli, w niesprzyjających, tajemniczych okolicznościach. Czy jednak będzie na tyle silna, by przeciwstawić się demonom i złowrogim siłom natury?\r\n"Autorka przenosi nas do świata słowiańskiej mitologii, pełnego stworzeń, które znamy z opowieści babć i dziadków. Topielica znad Świtezi to propozycja dla wszystkich tych, którzy od lektury oczekują zarówno wartkiej akcji, jak i pierwiastka tajemnicy, niedającego się objąć rozumem, ale zapadającego w pamięć na długo."',
      title: 'Topielica ze Świtezi',
    },
    en: {
      quotation: "If you don't believe in yourself, no one else will either",
      description:
        "Moving to Belarus is not what any teenager dreams of. A 16 year-old Wojtek doesn't have a choice because his father has just received a lucrative offer. The boy knows, that his father needs it because it may help him forget about his wife's",
      title: 'Topielica ze Świtezi',
    },
  },
  {
    trailer: 'Rfp4q-nZFPA ',
    review: 'https://lubimyczytac.pl/ksiazka/4917886/klatwa-siostr#opinie',
    bookstore: 'https://lubimyczytac.pl/ksiazka/4917886/klatwa-siostr#kup',
    background_image: '',
    cover: '',
    pl: {
      quotation: 'Kto zwycięży? Czy stoczyć trzeba będzie kolejny bój? Czy da się uciec przed odkrytym ...',
      description:
        'Klara i Basia - bliźniaczki, które różnią się od siebie tak bardzo, jak to tylko możliwe. Ogień i woda, umysł ścisły i klasyczna humanistka, skryta i mająca problem z okazywaniem uczuć dziewczyna i dusza towarzystwa. Nastolatki łączy jedno - chęć poznania odpowiedzi na pytanie: kim jest ich ojciec? To właśnie w jego osobie siostry upatrują szansy na rozwikłanie zagadki związanej z własnymi umiejętnościami, nie do końca dającymi się objąć rozumem. Na drodze do prawdy stoi matka, która strzeże swej tajemnicy, chcąc uchronić dzieci przed efektami podjętej przed laty decyzji o inseminacji. Powracająca wizja o potwornej zbrodni wojennej i koszmarze rozstrzelanych mężczyzn sprawi, że siostry Soboczyńskie wkroczą w świat upiorów i innych demonów, które szukają pomocy, pragnąc w końcu zaznać spokoju. Czy Klarze i Basi uda się spełnić marzenie o życiu typowych nastolatek? Czy dowiedzą się prawdy o swoich darach i będą w stanie je zaakceptować? Na te i wiele innych pytań poznacie odpowiedzi, czytając Klątwę sióstr - książkę pełną rodzinnych tajemnic, okraszoną nutą słowiańskiej mitologii, tak charakterystycznej dla twórczości Agnieszki Kaźmierczyk.',
      title: 'Klątwa sióstr',
    },
    en: {
      quotation: 'Who will win? Will there be another fight? Is it possible to escape destiny?',
      description:
        'Clara and Barbra - twin sisters who are as different as possible. Fire and water, a strict mind and a classical humanist, girl who has a problem with showing affection, and the soul of the party. Teenagers have one thing in common - [...]',
      title: 'Klątwa sióstr',
    },
  },
  {
    trailer: '',
    review: 'https://lubimyczytac.pl/ksiazka/4943946/rusalka#opinie',
    bookstore:
      'https://www.amazon.com/Rusalka-Agnieszka-Kazmierczyk/dp/1735345687?fbclid=IwAR2XoyAwMh8ih3EEohcNVd0Msj9eDzqMo0COnDYnx1XdthBvp7dqHZIttjs',
    background_image: '',
    cover: '',
    pl: {
      quotation: 'Jeśli Ty nie wierzysz w siebie, nikt inny także w Ciebie nie uwierzy',
      description:
        'Przeprowadzka na Białoruś nie jest szczytem marzeń nastolatka. Szesnastoletni Wojtek nie ma jednak wyboru, gdyż jego ojciec właśnie tam otrzymał intratną propozycję pracy.',
      title: 'Rusalka',
    },
    en: {
      quotation: "If you don't believe in yourself, no one else will either",
      description:
        "Moving to Belarus is not a teenager's dream come true. However, sixteen-year-old Wojtek has no choice, because his father received a lucrative job offer there. Perhaps it will help him break free from the memories of his mother's death and start living in the present. Wojtek has no idea that what he will meet at Lake Świteź, made famous by Adam Mickiewicz, will change his life forever.Svetlana dreams of becoming an ordinary girl - going to school, having friends, perhaps falling in love. A seventeen-year-old is disturbed by one fact - she is a rusalka, a drowned girl, a demon who, by its nature, should attract and kill people as soon as they enter her territory. When the paths of the two cross, their lives will change. Their love was born slowly, in unfavorable, in mysterious circumstances. But will it be strong enough to resist the demons and the malevolent forces of nature? The author takes us to the world of Slavic mythology, full of creatures we know from the stories of our grandparents.",
      title: 'Rusalka',
    },
  },
  {
    trailer: 'https://www.youtube.com/watch?v=3opG7Xwrzl4&ab_channel=%C5%81ukaszKafel',
    review: 'https://lubimyczytac.pl/ksiazka/4956390/epidemia-uczuc#opinie',
    bookstore: 'https://lubimyczytac.pl/ksiazka/4956390/epidemia-uczuc#kup',
    background_image: '',
    cover: '',
    pl: {
      quotation:
        'Pamiętaj tylko, że jesteś twórcą swojej rzeczywistości, swojego świata. Jeśli tylko się skupisz, jesteś w stanie stworzyć niemal wszystko.',
      description:
        'W życiu Magdy, dwudziestolatki, która wkrótce ma rozpocząć studia medyczne na prestiżowej uczelni, nie ma miejsca na spontaniczność i porywy uczuć. Matka dziewczyny jedną decyzją burzy cały jej poukładany świat – wyjazd do małej miejscowości na drugim końcu Polski i zajęcie się rękodziełem zakrawa na szaleństwo nie tylko w oczach córki, ale i męża. Choć nie padają słowa o rozwodzie, Magda obawia się, że rozłąka prędzej czy później doszczętnie zniszczy jej rodzinę. Wbrew rozsądkowi postanawia dołączyć do matki, a następnie sprowadzić w nowe miejsce również ojca. Nie ma pojęcia, że ta decyzja całkowicie przewartościuje jej priorytety. Kiedy dziewczyna poznaje Daniela, nastolatka mieszkającego z paczką znajomych w domu pośrodku lasu, doświadcza całej palety intensywnych uczuć, które do tej pory były jej obce. Niestety przeszłość chłopaka, owiana tajemnicą, może przekreślić szczęście zakochanych. Kim są młodzi ludzie zamieszkujący leśne osiedle? Jaki mroczny sekret skrywają? Czy tragiczne wydarzenia sprzed wielu lat ponownie zbiorą krwawe żniwo?',
      title: 'Epidemia uczuć',
    },
    en: {
      quotation:
        'Remember that you are the creator of your reality, your world. If you just focus, you can create  anything.',
      description:
        'Remember that you are the creator of your reality, your world. If you just focus, you can create  anything.',
      title: 'Epidemia uczuć',
    },
  },
  {
    trailer: 'https://www.youtube.com/watch?v=3opG7Xwrzl4&ab_channel=%C5%81ukaszKafel',
    review: 'https://lubimyczytac.pl/ksiazka/5009774/klatwa-siostr-czas-odpowiedzi?',
    bookstore: '',
    background_image: '',
    cover: '',
    pl: {
      quotation:
        'Pamiętaj tylko, że jesteś twórcą swojej rzeczywistości, swojego świata. Jeśli tylko się skupisz, jesteś w stanie stworzyć niemal wszystko.',
      description:
        'Klara i Basia, bliźniaczki, które dały się poznać jako posiadaczki tajemniczych darów właśnie rozpoczynają naukę w klasie maturalnej. Agata, ich matka wydaje się być wreszcie szczęśliwą i spełnioną w związku z policjantem - Mateuszem.\r\n\r\nCzy kolejny koszmar senny Klary okaże się prawdą? Czy moralny nakaz niesienia pomocy powinien przyczynić się do zweryfikowania własnych priorytetów, marzeń i pragnień?\r\n\r\nDruga część ,,Klątwy sióstr" Agnieszki Kaźmierczyk. Tym razem Soboczyńskie staną przed nie lada wyzwaniem: będą zmuszone odszukać zaginione dziewczęta, ale...\r\n\r\nCzy one zniknęły naprawdę, czy to tylko wyobraźnia Klary? Co łączy wszystkie sprawy? Czy uda się zapobiec śmierci porwanych? Ile z siebie można poświęcić dla ratowania innych?\r\n\r\nMistyka, wiara, słowiańskie wierzenia, błędy przeszłości, wina, która nie pozwala zaznać spokoju wiecznego, realność i duchowość. W tej powieści nie ma czasu na nudę. Pytania się mnożą, a na odpowiedzi trzeba trochę poczekać, bo duch bywają kapryśne...',
      title: 'Klątwa sióstr. Czas odpowiedzi',
    },
    en: {
      quotation:
        'Pamiętaj tylko, że jesteś twórcą swojej rzeczywistości, swojego świata. Jeśli tylko się skupisz, jesteś w stanie stworzyć niemal wszystko.',
      description:
        'Klara i Basia, bliźniaczki, które dały się poznać jako posiadaczki tajemniczych darów właśnie rozpoczynają naukę w klasie maturalnej. Agata, ich matka wydaje się być wreszcie szczęśliwą i spełnioną w związku z policjantem - Mateuszem.\r\n\r\nCzy kolejny koszmar senny Klary okaże się prawdą? Czy moralny nakaz niesienia pomocy powinien przyczynić się do zweryfikowania własnych priorytetów, marzeń i pragnień?\r\n\r\nDruga część ,,Klątwy sióstr" Agnieszki Kaźmierczyk. Tym razem Soboczyńskie staną przed nie lada wyzwaniem: będą zmuszone odszukać zaginione dziewczęta, ale...\r\n\r\nCzy one zniknęły naprawdę, czy to tylko wyobraźnia Klary? Co łączy wszystkie sprawy? Czy uda się zapobiec śmierci porwanych? Ile z siebie można poświęcić dla ratowania innych?\r\n\r\nMistyka, wiara, słowiańskie wierzenia, błędy przeszłości, wina, która nie pozwala zaznać spokoju wiecznego, realność i duchowość. W tej powieści nie ma czasu na nudę. Pytania się mnożą, a na odpowiedzi trzeba trochę poczekać, bo duch bywają kapryśne...',
      title: 'Klątwa sióstr. Czas odpowiedzi',
    },
  },
];

export const useBooksQuery = () => {
  const images = useStaticQuery(
    graphql`
      query BooksQuery {
        topielica: file(relativePath: { eq: "books/topielica.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        klatwa: file(relativePath: { eq: "books/klatwa.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rusalka: file(relativePath: { eq: "books/rusalka.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        epidemia: file(relativePath: { eq: "books/epidemia.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        epidemia_bg: file(relativePath: { eq: "books/epidemia_bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        klatwa2: file(relativePath: { eq: "books/klatwa2.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  data[0].background_image = images.topielica.childImageSharp.fluid;
  data[1].background_image = images.klatwa.childImageSharp.fluid;
  data[2].background_image = images.rusalka.childImageSharp.fluid;
  data[3].background_image = images.epidemia_bg.childImageSharp.fluid;
  data[4].background_image = images.klatwa2.childImageSharp.fluid;

  data[0].cover = images.topielica.childImageSharp.fluid;
  data[1].cover = images.klatwa.childImageSharp.fluid;
  data[2].cover = images.rusalka.childImageSharp.fluid;
  data[3].cover = images.epidemia.childImageSharp.fluid;
  data[4].cover = images.klatwa2.childImageSharp.fluid;

  return data;
};
